import React from 'react';

import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { Carousel } from '@johnlewispartnership/wtr-ingredients/ingredients/Carousel';

import CategoryCard from 'components/CategoryLinks/CategoryCarousel/CategroyCard';

import styles from './index.scss';
import { LinkCategory } from '../types';

type CategoryCarouselProps = { categories: LinkCategory[] };

const CategoryCarousel = ({ categories }: CategoryCarouselProps) => (
  <nav aria-label="List of sub-categories within groceries" data-testid="category-list-nav">
    <div className={styles.textLinksHeader}>
      <Typography component="h2" styleAs="sectionHeading">
        Browse categories
      </Typography>
    </div>
    <div className={styles.linksWrapper}>
      <Carousel ariaName="Browse Categories" buttonTheme="round" showHorizontalScrollbar>
        {categories.map(({ isOffer, name, url }) => (
          <li data-testid="links-list" key={`${name}${url}`}>
            <CategoryCard isOffer={isOffer} name={name} url={url} />
          </li>
        ))}
      </Carousel>
    </div>
  </nav>
);

export default CategoryCarousel;
