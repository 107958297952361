import React from 'react';

import { useExperiments } from 'components/Experiment/useExperiments';
import { CATEGORY_NAVIGATION } from 'constants/monetateExperiments';
import CategoryPills from 'components/CategoryLinks/CategoryPills';
import DefaultCategoryLinks from 'components/CategoryLinks/DefaultCategoryLinks';
import CategoryTextLinks from './CategoryTextLinks';
import CategoryCarousel from './CategoryCarousel';

import { CategoryLinksProps } from './types';

const CategoryLinks = ({
  ariaLabel = undefined,
  isGLP = false,
  categories = [],
  componentId = null,
  cqResponsiveClasses = [],
  hasSeeMore = true,
  hasTitleWithThroughLine = true,
  label = null,
  theme = 'grey',
  title,
}: CategoryLinksProps) => {
  const useCategoryLinks = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let ComponentWithExperiment: React.FC<any> = DefaultCategoryLinks;
    let isDefault = true;
    const { getDecisionById } = useExperiments();

    const experimentVariant = getDecisionById(CATEGORY_NAVIGATION.experiment).variant;

    if (!isGLP) return { ComponentWithExperiment, isDefault: true };

    switch (experimentVariant) {
      case 'showLinks':
        ComponentWithExperiment = CategoryTextLinks;
        isDefault = false;
        break;
      case 'showCarousel':
        isDefault = false;
        ComponentWithExperiment = CategoryCarousel;
        break;
      case 'showPills':
        isDefault = false;
        ComponentWithExperiment = CategoryPills;
        break;
      default:
        isDefault = true;
        break;
    }

    return { ComponentWithExperiment, isDefault };
  };

  const { ComponentWithExperiment, isDefault } = useCategoryLinks();

  if (!categories.length) return null;

  return (
    <>
      {isDefault ? (
        <ComponentWithExperiment
          ariaLabel={ariaLabel}
          categories={categories}
          componentId={componentId}
          cqResponsiveClasses={cqResponsiveClasses}
          hasSeeMore={hasSeeMore}
          hasTitleWithThroughLine={hasTitleWithThroughLine}
          label={label}
          theme={theme}
        />
      ) : (
        <ComponentWithExperiment categories={categories} title={title} />
      )}
    </>
  );
};

export default CategoryLinks;
