import React, { useRef } from 'react';
import { bool, number, object, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import { dataLayer } from 'analytics/data-layer';
import { Link } from 'react-router-dom';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import { useMakeSelector } from 'hooks/use-make-selector';
import { makeGetCategoryPathFromId } from 'redux/modules/taxonomy/selectors';

import {
  DEFAULT_OFFERS_LINK,
  FAVOURITES_OFFERS_LINK,
  MYWAITROSE_OFFERS_LINK,
} from 'constants/offers-navigation-button-types';

import { mergeQueryParams } from 'utils/searchQuery';

import { useSeeMoreItemVisible } from '../SeeMore';

import styles from './CategoryLink.scss';

const getLinkClass = type => {
  switch (type) {
    case DEFAULT_OFFERS_LINK:
      return styles.offersLink;
    case FAVOURITES_OFFERS_LINK:
      return styles.favouritesLink;
    case MYWAITROSE_OFFERS_LINK:
      return styles.myWaitroseLink;
    default:
      return styles.link;
  }
};

const logAnalytics = link => () => dataLayer.push({ event: 'category_link_click', link });

const CategoryLink = ({
  category: { name, search, type, url, isOffer, categoryId },
  location: { search: locationSearch },
  position,
  theme,
}) => {
  const linkRef = useRef();
  const visible = useSeeMoreItemVisible(linkRef);

  const pathFromCategoryId = useMakeSelector(makeGetCategoryPathFromId, categoryId);

  const to = {
    pathname: url?.toLowerCase() || pathFromCategoryId,
    search: mergeQueryParams(locationSearch, search),
  };

  const linkProps = {
    'aria-hidden': visible ? null : 'true',
    'aria-label': type === MYWAITROSE_OFFERS_LINK ? 'My Waitrose' : name,
    className: classNames(getLinkClass(type), {
      [styles.redHighlight]: isOffer,
      [styles.themeGrey]: theme === 'grey',
      [styles.themeRed]: theme === 'red',
      [styles.themeWhite]: theme === 'white',
    }),
    tabIndex: visible ? '0' : '-1',
    to,
    underline: 'hover',
    onClick: logAnalytics({
      text: name,
      destination: to.pathname + to.search,
      index: position,
    }),
  };

  return (
    <TextLink component={Link} {...linkProps}>
      <span className={styles.label}>{name}</span>
    </TextLink>
  );
};

CategoryLink.propTypes = {
  category: shape({
    name: string,
    // eslint-disable-next-line react/forbid-prop-types
    search: object, // free-form object of key:value pairs
    type: oneOf([DEFAULT_OFFERS_LINK, FAVOURITES_OFFERS_LINK, MYWAITROSE_OFFERS_LINK]),
    url: string,
    isOffer: bool,
    categoryId: string,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  position: number.isRequired,
  theme: oneOf(['grey', 'red', 'white']),
};

CategoryLink.defaultProps = {
  theme: 'grey',
};

export default CategoryLink;
